const tooltip = {
	trigger: 'axis',
	axisPointer: {
		type: 'shadow'
	}
	/* "formatter":"function(params) {const atraque_inicioops = `<b>${params[0].seriesName}</b>: ${params[0].data.substring(10)}`;const marca_confianza = `<b>${params[1].seriesName}</b>: ${params[1].data.substring(10)}`;return `${atraque_inicioops}<br />${marca_confianza}`;}" */
};
const grid = {
	left: '3%',
	right: '4%',
	bottom: '3%',
	containLabel: true
};
const legend = {
	show: true
};
const xAxis = {
	type: 'category'
};
const yAxis = { type: 'value' };

const lineChartOptions = {
	tooltip,
	grid,
	legend,
	xAxis,
	yAxis,
	series: []
};

export default lineChartOptions;
