<template>
	<v-card outlined elevation="1" :style="{ width: width, height: height }" :loading="loading" v-resize="onResize">
		<v-card-title v-if="title">{{ title }}</v-card-title>
		<v-divider v-if="title" />
		<v-card-text>
			<div :id="'puiwidgetechart_' + id" class="chart" :style="{ width: defaultWidth, height: defaultHeight }" ref="echart" />
			<span v-if="noData">{{ noData }}</span>
		</v-card-text>
	</v-card>
</template>

<script>
import * as echarts from 'echarts';
// echarts themes (infographic | dark | roma | vintage)
require('echarts/theme/infographic');
require('echarts/theme/dark');
require('echarts/theme/roma');
require('echarts/theme/vintage');

import defaultOptions from './OperationsChartOptions.js';

export default {
	name: 'OperationsChart',
	props: {
		id: {
			type: Number,
			required: true
		},
		resized: {
			type: Object,
			required: false
		},
		range: {
			type: Object,
			required: false
		},
		configuration: {
			type: Object,
			required: true
		},
		extra: {
			type: Object,
			required: false
		},
		subscribe_to: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			loading: true,
			title: null,
			chart: null,
			noData: null
		};
	},
	computed: {
		defaultWidth() {
			if (this.resized && this.resized.width) {
				//return `${this.resized.width - 50}px`;
			}
			return '100%';
		},
		defaultHeight() {
			if (this.resized && this.resized.height) {
				return `${this.resized.height - 100}px`;
			}
			return '100%';
		},
		date() {
			return this.range.from + '/' + this.range.to;
		}
	},
	watch: {
		resized: {
			handler() {
				this.onResize();
			},
			deep: true
		}
	},
	mounted() {
		this.initChart();
		this.$puiEvents.$on(this.subscribe_to, this.reload);
	},
	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
		this.$puiEvents.$off(this.subscribe_to);
	},
	methods: {
		reload() {
			this.loading = true;
			this.initChart(true);
		},
		onResize() {
			if (this.chart) {
				this.chart.resize();
			}
		},
		parseFunctions(o) {
			if (o.yAxis && o.yAxis.axisLabel && o.yAxis.axisLabel.formatter) {
				o.yAxis.axisLabel.formatter = new Function(`return (${o.yAxis.axisLabel.formatter})`)();
			}

			console.log(o.tooltip.formatter);
			if (o.tooltip && o.tooltip.formatter) {
				o.tooltip.formatter = new Function(`return (${o.tooltip.formatter})`)();
			}

			if (o.tooltip && o.tooltip.valueFormatter) {
				o.tooltip.valueFormatter = new Function(`return (${o.tooltip.valueFormatter})`)();
			}

			return o;
		},
		initChart(eventCalled = false) {
			if (!this.configuration) {
				this.title = `Example Chart`;
				this.chart = echarts.init(this.$refs.echart);
				this.chart.setOption(defaultOptions);
				this.loading = false;
				return;
			}

			this.title = this.configuration.name;

			const theme = null;
			const url = '/chart/get';
			const params = {
				id: this.id,
				range: this.date,
				op: this.configuration.operation
			};

			if (this.extra) {
				for (const key in this.extra) {
					params[key] = this.extra[key];
				}
			}

			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					if (response && response.data) {
						let chartOptions = structuredClone(defaultOptions);
						const echartsConfig = JSON.parse(this.configuration.echartsConfig);
						if (this.configuration.echartsConfig) {
							for (const key in echartsConfig) {
								chartOptions[key] = echartsConfig[key];
							}
						}

						chartOptions = this.parseFunctions(chartOptions);

						if (response.data.categories) {
							chartOptions.xAxis.data = response.data.categories;
						}

						/* const filteredSeries = response.data.series.filter((v) => {
							return Boolean(v.length);
						}); */

						const filteredSeries = response.data.series;

						if (Boolean(echartsConfig.series)) {
							chartOptions.series = echartsConfig.series;
							for (let i = 0; i < chartOptions.series.length; i++) {
								console.log(filteredSeries);
								chartOptions.series[i].data = filteredSeries[i].map((v) => {
									return v.serie;
								});
							}
						} else {
							chartOptions.series = filteredSeries.map((v, idx) => {
								let m = {};
								m.data = v.map((e) => {
									return e.serie;
								});
								m.type = echartsConfig.series ? echartsConfig.series[idx].type : 'line';
								m.name = echartsConfig.series ? echartsConfig.series[idx].name : idx;
								return m;
							});
						}

						if (eventCalled) {
							this.chart.clear();
						}
						console.log(chartOptions);
						this.chart = echarts.init(this.$refs.echart, theme);
						this.chart.setOption(chartOptions);
						this.loading = false;

						if (chartOptions.series.length) {
							this.noData = null;
						} else {
							this.noData = this.$t('puiwidget.nodata');
						}
					} else {
						console.log('No response data');
						this.noData = this.$t('puiwidget.nodata');
						this.loading = false;
					}
				},
				(error) => {
					console.log(error);
					this.noData = this.$t('puiwidget.nodata');
					this.loading = false;
				}
			);
		}
	}
};
</script>
